// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-casa-autonoma-index-tsx": () => import("./../../../src/pages/casa-autonoma/index.tsx" /* webpackChunkName: "component---src-pages-casa-autonoma-index-tsx" */),
  "component---src-pages-contact-page-index-tsx": () => import("./../../../src/pages/contact-page/index.tsx" /* webpackChunkName: "component---src-pages-contact-page-index-tsx" */),
  "component---src-pages-contact-success-index-tsx": () => import("./../../../src/pages/contact-success/index.tsx" /* webpackChunkName: "component---src-pages-contact-success-index-tsx" */),
  "component---src-pages-coverage-page-index-tsx": () => import("./../../../src/pages/coverage-page/index.tsx" /* webpackChunkName: "component---src-pages-coverage-page-index-tsx" */),
  "component---src-pages-demo-page-index-tsx": () => import("./../../../src/pages/demo-page/index.tsx" /* webpackChunkName: "component---src-pages-demo-page-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manual-components-main-content-components-app-item-index-tsx": () => import("./../../../src/pages/manual/components/MainContent/components/AppItem/index.tsx" /* webpackChunkName: "component---src-pages-manual-components-main-content-components-app-item-index-tsx" */),
  "component---src-pages-manual-components-main-content-index-tsx": () => import("./../../../src/pages/manual/components/MainContent/index.tsx" /* webpackChunkName: "component---src-pages-manual-components-main-content-index-tsx" */),
  "component---src-pages-manual-components-question-list-components-question-item-index-tsx": () => import("./../../../src/pages/manual/components/QuestionList/components/QuestionItem/index.tsx" /* webpackChunkName: "component---src-pages-manual-components-question-list-components-question-item-index-tsx" */),
  "component---src-pages-manual-components-question-list-index-tsx": () => import("./../../../src/pages/manual/components/QuestionList/index.tsx" /* webpackChunkName: "component---src-pages-manual-components-question-list-index-tsx" */),
  "component---src-pages-manual-index-tsx": () => import("./../../../src/pages/manual/index.tsx" /* webpackChunkName: "component---src-pages-manual-index-tsx" */),
  "component---src-pages-our-content-index-tsx": () => import("./../../../src/pages/our-content/index.tsx" /* webpackChunkName: "component---src-pages-our-content-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-promotion-components-contact-button-index-tsx": () => import("./../../../src/pages/promotion/components/ContactButton/index.tsx" /* webpackChunkName: "component---src-pages-promotion-components-contact-button-index-tsx" */),
  "component---src-pages-promotion-index-tsx": () => import("./../../../src/pages/promotion/index.tsx" /* webpackChunkName: "component---src-pages-promotion-index-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-page-index-tsx": () => import("./../../../src/templates/page/index.tsx" /* webpackChunkName: "component---src-templates-page-index-tsx" */)
}

